import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/styles/styles.css";
import Helmet from "react-helmet"

export default function Perks() {

  return (
        <div className="tab-pane show active" id="pills-Perks">
          <Helmet title="Perks of owning an Everyday Goddess" />
              <div className="about-content">
                <div className="title-box pl-0 text-left bg-blue-mobile">
                  <h1>Perks</h1>
                </div>
                <div className="about-content-box  perks-box">
                  <ul className="perks-content-box">
                    <li>
                      {" "}
                      Each Goddess is a pass to the Shiny ecosystem, granting access
                      to exclusive airdrops and companion NFTs.{" "}
                    </li>
                    <li>
                      {" "}
                      Goddesses will be integrated into Shiny’s tokenomics system.
                      They will claim a utility token used to mint certain future
                      NFT collections, access allow-lists, exclusive Discord perks,
                      and more.{" "}
                    </li>
                    <li>
                      {" "}
                      Guaranteed allocation in future collections in the Shiny
                      ecosystem, including launchpad projects.{" "}
                    </li>
                    <li>Full commercial rights to any Goddesses you own.</li>
                    <li>
                      {" "}
                      Royalties paid out on any merchandise that includes your
                      Goddess (with your consent of course).{" "}
                    </li>
                    <li>Eligibility for rewards from our community vault.</li>
                    <li>
                      {" "}
                      A Goddess serves as a pass to meetups and networking events.{" "}
                    </li>
                  </ul>
                </div>
              </div>
        </div>


  );
}
