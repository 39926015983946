import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
	GlobalEventsContext,
	GlobalEventsReducer,
} from './shared/context/global-event';

const queryClient = new QueryClient();

function Index() {
	const [state, dispatch] = React.useReducer(GlobalEventsReducer, {});
	return (
		// <React.StrictMode>
		<GlobalEventsContext.Provider value={{ state, dispatch }}>
			<QueryClientProvider client={queryClient}>
				<App />
			</QueryClientProvider>
		</GlobalEventsContext.Provider>
		// </React.StrictMode>
	);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Index />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
